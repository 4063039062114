import React from "react";
// import {Link } from "@gatsbyjs/reach-router";
import * as containerStyles from "./stationContainer.module.css"
import line_red from "../images/line_red.svg";
import line_green from "../images/line_green.svg";
import line_gold from "../images/line_gold.svg";
import line_orange from "../images/line_orange.svg";
import line_pink from "../images/line_pink.svg";
import line_turquoise from "../images/line_turquoise.svg";
import line_purple from "../images/line_purple.svg";


const lineImages = {
  Red: line_red,
  Green: line_green,
  Gold: line_gold,
  Orange: line_orange,
  Pink: line_pink,
  Turquoise :line_turquoise,
  Purple: line_purple,
};

function getLineImage(line) {
  return lineImages[line];
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function StationContainer({href, station_image, station_name, station_name_ar, station_name_en, station_line}) {
  // Import result is the URL of your image
  let textArClass = station_name_ar.length > 12 ? containerStyles.stationNameArXs : null;
  let textEnClass = station_name_en.length > 12 ? containerStyles.stationNameEnXs : containerStyles.stationNameEn;
  return (
    <a
      className={containerStyles.stationContainer} 
      href={href}> 
      {/* <img className={containerStyles.lineIcon}
      width="640" height="360"
      src={station_image} alt={station_name}/> */}
      {station_line.map((station_line_name) => (
        <img className={containerStyles.lineIcon}
        width="640" height="360"
          src={getLineImage(capitalizeFirstLetter(station_line_name))}
          alt={`${station_name_en} ${station_line_name} line`}
          key={station_line_name}
        />
      ))}
      <div className={containerStyles.stationName}> 
        <p className={textArClass}>{station_name_ar}</p>
        <p className={textEnClass}>{station_name_en}</p>
      </div> 
    </a>
  );
}
export default StationContainer;
