import React from "react";
import {useEffect} from "react";
import "./index.css";
import Layout from "../componenets/layout";
import FilterableStationTable from "../componenets/FilterableStationTable";
import stationData from "../data/stationData";
// import { graphql } from "gatsby";
// import LightBoxLight from "../componenets/LightBoxLight";
import { Helmet } from "react-helmet";

export default function IndexPage({ location }) {
  useEffect(() => {
    // let element = document.getElementById("searchBar");
    // element.scrollIntoView();
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }, []);
  // const image =
  //   data.networkmap_xl.childImageSharp.gatsbyImageData.images.fallback.src;
  let title = "استكشف المحطات - مترو وترام الريل | Explore Stations - metro tram Qatar Rail"
  return (
    <Layout pageTitle={title} imgsrc={'/media/img/explore_stations_header.svg'}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/metrotram/app_icon.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@metrotram_qa" />
        <meta name="twitter:widgets:new-embed-design" content="on" />
        <meta name="twitter:widgets:csp" content="on" />

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content={title} />
      </Helmet>
      <div className="flex flex-col text-center md:px-5 text-2xl">

        {/* <h1 className="md:text-4xl font-medium title-font md:mb-4 text-xl">
        استكشف المحطات
        </h1>
        <h1 className="md:text-4xl font-medium title-font md:mb-4">
          {" "}
          Explore stations
        </h1> */}
        {/* <div className="items-center shadow ring-4 ring-gray-500 rounded-lg p-1 m-2">
          <LightBoxLight
            src="/media/map/networkmap.png"
            className="object-cover md:h-80 h-52 w-full"
            alt="Network map"
            title="Network map"
          />{" "}
        </div> */}

          <div className="iconButtonBlock">

            <a href={"/media/map/tram_networkmap.png"} className="iconButton">
              <img
                src="/media/img/tram_networkmap_button.svg"
                // className="object-cover md:h-80 h-52 w-full"
                alt="Lusail Tram Map"
                className="iconButtonImage"
                title="Lusail Tram Map"
                width="640" height="360"
              />
              <span className="iconButtonText">
                <div className="pb-0.5">{"خريطة ترام لوسيل"}</div> <div>{"Lusail Tram Map"}</div>
              </span>
            </a>
            <a href={"/media/map/metro_networkmap.png"} className="iconButton">
              <img
                src="/media/img/metro_networkmap_button.svg"
                // className="object-cover md:h-80 h-52 w-full"
                className="iconButtonImage"
                alt="Doha Metro Map"
                title="Doha Metro Map"
                width="640" height="360"
              />
              <span className="iconButtonText">
                <div className="pb-0.5">{"خريطة مترو الدوحة"}</div> <div>{"Doha Metro Map"}</div>
              </span>
            </a>
            <a href={"https://www.qr.com.qa/qrgis/PlanMyJourney/"} className="iconButton">
              <img
                src="/media/img/plan_my_journey_metro.svg"
                // className="object-cover md:h-80 h-52 w-full"
                className="iconButtonImage"
                alt="Plan My Journey"
                title="Plan My Journey"
                width="640" height="360"
              />
              <span className="iconButtonText">
                <div className="pb-0.5">{"التخطيط لرحلتي"}</div> <div>{"Plan My Journey"}</div>
              </span>
            </a>
          </div>
          

      </div>
      <div className="relative flex px-5 pt-5 pb-2 items-center">
    <div className="flex-grow border-t border-white-400"></div>
</div>
      <FilterableStationTable stations={stationData} />
    </Layout>
  );
}

// export const query = graphql`
//   {
//     networkmap_xl: file(relativePath: { eq: "networkmap.lx.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: CONSTRAINED)
//       }
//     }
//     networkmap: file(relativePath: { eq: "networkmap.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: CONSTRAINED)
//       }
//     }
//   }
// `;
