import React from "react";
import StationContainer from "./stationContainer";
import line_red from "../images/line_red.svg";
import line_green from "../images/line_green.svg";
import line_gold from "../images/line_gold.svg";
import line_orange from "../images/line_orange.svg";
import line_pink from "../images/line_pink.svg";
import line_turquoise from "../images/line_turquoise.svg";
import line_purple from "../images/line_purple.svg";

import * as containerStyles from "./FilterableStationTable.module.css";
import "./FilterableStationTable.css";

const lineImages = {
  Red: line_red,
  Green: line_green,
  Gold: line_gold,
  Orange: line_orange,
  Pink: line_pink,
  Turquoise :line_turquoise,
  Purple: line_purple,
};

function getLineImage(station_line) {

  // if (station_line.includes("Red") && station_line.includes("Green") && station_line.includes("Gold")){
  //   return line_red_green_gold;
  // }
  // if (station_line.includes("Red") && station_line.includes("Green")){
  //   return line_red_green;
  // }
  // if (station_line.includes("Red") && station_line.includes("Orange")){
  //   return line_red_orange;
  // }
  return lineImages[station_line[0]];
}

// function occurrences(a, i, e) {
//   if (((a += ""), (i += "").length <= 0)) return a.length + 1;
//   var n = 0;
//   for (var t = 0, _ = e ? 1 : i.length; (t = a.indexOf(i, t)) >= 0; ) {
//     ++n;
//     t += _;
//   }
//   return n;
// }
// function containsAll(a, i) {
//   for (const e of i)
//     if (!a.includes(e) || occurrences(a, e) < occurrences(i, e)) return !1;
//   return !0;
// }

function CheckSelected(linesSelected, station_line) {
  let res = false;
  for (const line of linesSelected) {
    for (const l of station_line) {
      if (line.name === l.toLowerCase()) {
        res = line.checked || res;
      }
    }
  }
  return res;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class StationTable extends React.Component {
  render() {
    const filterText = this.props.filterText;
    const linesSelected = this.props.linesSelected;
    const rows = [];
    this.props.stations.forEach((station) => {
      if ((CheckSelected(linesSelected, station.station_line) && filterText.length === 0)) {
        rows.push(
          <StationContainer
            href={`/stations?name=${station.station_name}`}
            getLineImage={getLineImage}
            station_image={getLineImage(station.station_line)}
            capitalizeFirstLetter={capitalizeFirstLetter}
            station_line={station.station_line}
            key={station.station_name}
            station_name_ar={station.station_name_ar}
            station_name_en={station.station_name_en}
            station_name={station.station_name}
          ></StationContainer>
        );
      }
      else if (
        // (filterText.length != 0) && (containsAll(
        //   station.station_name_en.replace(" ", "").toLowerCase(),
        //   filterText.replace(" ", "").toLowerCase()
        // ) ||
        //   containsAll(
        //     station.station_name_ar.replace(" ", "").toLowerCase(),
        //     filterText.replace(" ", "").toLowerCase()
        //   )) 
        station.station_name_ar.replace(" ", "").toLowerCase().includes(filterText.replace(" ", "").toLowerCase())
        ||
        station.station_name_en.replace(" ", "").toLowerCase().includes(filterText.replace(" ", "").toLowerCase())
      ) {
        rows.push(
          <StationContainer
            href={`/stations?name=${station.station_name}`}
            getLineImage={getLineImage}
            station_image={getLineImage(station.station_line)}
            capitalizeFirstLetter={capitalizeFirstLetter}
            station_line={station.station_line}
            key={station.station_name}
            station_name_ar={station.station_name_ar}
            station_name_en={station.station_name_en}
            station_name={station.station_name}
          ></StationContainer>
        );
      }
    });
    if (rows.length !== 0){
      return <div className={containerStyles.StationTable}>{rows}</div>;
    }
    return <div className="flex flex-col text-center md:px-5 text-md md:text-2xl">
      <h1 className="font-medium title-font">
      لا توجد نتائج
        </h1>
        <h1 className="font-medium title-font">
          {" "}
          There are no results
        </h1>
    </div>;
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
    this.handleLineChange = this.handleLineChange.bind(this);
  }

  handleFilterTextChange(e) {
    this.props.onFilterTextChange(e.target.value);
  }

  componentDidMount(){

      let element = document.getElementById("searchBar");
      element.addEventListener('keyup', (keyboardEvent) => {
        const key = keyboardEvent.code || keyboardEvent.keyCode;
        if (key === 'Enter' || key === 13) {
            element.blur();
        }
    });
  }

  handleLineChange(e) {
    this.props.onLineChange(e.target);
  }

  // scroll(e) {
  //   let element = document.getElementById("searchBar");
  //   element.scrollIntoView();
  // }
  

  render() {
    // let checkboxes = this.props.linesSelected.map((line) => (
    //   <div
    //     key={line.name}
    //     className="relative inline-block w-16 align-middle select-none transition duration-200 ease-in mx-6"
    //   >
    //     <div>
    //       <input
    //         type="checkbox"
    //         name={line.name}
    //         id={line.name}
    //         className="toggle-checkbox absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer"
    //         checked={line.checked}
    //         onChange={this.handleLineChange}
    //       />
    //       <label
    //         htmlFor={line.name}
    //         className="toggle-label block overflow-hidden w-16 h-8 rounded-full bg-gray-300 cursor-pointer"
    //       ></label>
    //     </div>
    //     <label
    //       htmlFor={line.name}
    //       className="flex flex-col justify-items-center"
    //     >
    //       <img
    //         className={containerStyles.lineIcon}
    //         width="640"
    //         height="360"
    //         src={getLineImage([capitalizeFirstLetter(line.name)])}
    //         alt={line.name}
    //       />
    //       <p>{capitalizeFirstLetter(line.name_ar)}</p>
    //       <p>{capitalizeFirstLetter(line.name)}</p>
    //     </label>
    //   </div>
    // ));
    return (
      <div id="box" className={containerStyles.SearchBarBlock}>
        {/* <div className={containerStyles.SearchBarBlock + " text-center "}>
          {checkboxes}
        </div> */}
        <input
          id="searchBar"
          type="search"
          value={this.props.filterText}
          onChange={this.handleFilterTextChange}
          placeholder="Search for a station ... ابحث عن محطة"
          className={containerStyles.SearchBar}
          onClick={this.scroll}
        />
        
      </div>
    );
  }
}

class FilterableStationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      linesSelected: [
        { checked: true, name: "red", name_ar: "الأحمر" },
        { checked: true, name: "green", name_ar: "الأخضر" },
        { checked: true, name_ar: "الذهبي", name: "gold" },
        { checked: true, name_ar: "البرتقالي", name: "orange" },
      ],
    };

    this.handleFilterTextChange = this.handleFilterTextChange.bind(this);
  }

  handleLineChange = (eventLine) => {
    
    let linesSelected = this.state.linesSelected;
    for (const line of linesSelected) {
      if (line.name === eventLine.name) {
        line.checked = eventLine.checked;
      }
    }
    this.setState({ linesSelected: linesSelected });
  };

  handleFilterTextChange(filterText) {
    // let element = document.getElementById("searchBar");
    // element.scrollIntoView();
    this.setState({
      filterText: filterText,
    });
  }

  scroll(e) {
    // let element = document.getElementById("searchBar");
    // element.scrollIntoView();
    //this.scroll
  }

  render() {
    return (
      <div id="filter" className={containerStyles.FilterableStationTable}>
        <SearchBar
          filterText={this.state.filterText}
          linesSelected={this.state.linesSelected}
          onFilterTextChange={this.handleFilterTextChange}
          onLineChange={this.handleLineChange}
        />
        <StationTable
          stations={this.props.stations}
          filterText={this.state.filterText}
          linesSelected={this.state.linesSelected}
        />
      </div>
    );
  }
}

export default FilterableStationTable;
